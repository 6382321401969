<template>
  <div class="reddit-index">
    <div class="rediit-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="reddit-content">
      <div>
        <div class="index-title">近7天舆情数据采集统计</div>
        <div id="echarts"></div>
      </div>
      <div>
        <div class="index-title">
          <div>最新采集范围</div>
          <span @click="goPath('/acquisition/range')">更多>></span>
        </div>
        <el-table
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            prop="subredditName"
            label="LOGO"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <img
                class="index-range-logo"
                :src="
                  scope.row.ico
                    ? scope.row.ico
                    : require('@/assets/imgs/i12.png')
                "
                alt=""
                :onerror="noPic"
              />
            </template>
          </el-table-column>

          <el-table-column prop="websiteName" label="名称"></el-table-column>
          <el-table-column prop="source" label="链接">
            <template slot-scope="scope">
              <a :href="scope.row.source" target="_blank">
                {{ scope.row.source }}
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态" width="90">
            <template slot-scope="data">
              <span v-if="data.row.state == 1">正常</span>
              <span v-else>结束</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="index-title">
          <div>我的关键词</div>
          <span @click="goPath('/key/words')">更多>></span>
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          :show-header="false"
          style="width: 100%; margin-top: 10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5.5px 0' }"
        >
          <el-table-column
            prop="groupName"
            label=""
            show-overflow-tooltip
          ></el-table-column>

          <!-- <el-table-column prop="createTime" label="创建时间"></el-table-column> -->
        </el-table>
      </div>
    </div>
    <div class="reddit-foot">
      <div class="foot-left">
        <div class="index-title">
          <div>新闻舆情</div>
          <span @click="goPath('/public/sentiment')">更多>></span>
        </div>
        <el-table
          :data="communityArr"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            prop="title"
            label="标题"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="websiteName"
            label="来源"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="issuingTime"
            label="发布时间"
            width="150"
          ></el-table-column>
        </el-table>
      </div>
      <div class="foot-right">
        <div class="index-title">
          <div>重点关注</div>
          <span @click="goPath('/focus/on')">更多>></span>
        </div>
        <el-table
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%; margin-top: 10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column
            prop="title"
            label="内容"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="websiteName"
            label="来源"
            show-overflow-tooltip
            width="200"
          ></el-table-column>
          <el-table-column
            prop="issuingTime"
            label="发布时间"
            show-overflow-tooltip
            width="160"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {
  statistics,
  collectionAmount,
  siteConfig,
  logRead
} from '@/api/dashboard.js'
import { webSite } from '@/api/rang.js'
import { planPage } from '@/api/keywords.js'
import { articlePage } from '@/api/sentiment.js'
import { remarkPage } from '@/api/sentiment.js'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          memo2: '结束日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/t2.png'),
          memo1: 0,
          memo2: '文章数量',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t3.png'),
          memo1: 0,
          memo2: '采集站点数量',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t4.png'),
          memo1: 0,
          memo2: '关键词数量',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t5.png'),
          memo1: '0',
          memo2: '重点关注数量',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t6.png'),
          memo1: '0',
          memo2: '搜素日志数量',
          bold: true,
          measurement: ''
        }
        // {
        //   img: require('../../assets/imgs/t2.png'),
        //   memo1: '0',
        //   memo2: '评论',
        //   bold: true,
        //   measurement: ''
        // }
      ],
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: []
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    info() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() {
    // 获取头部6个数据
    this.getHead()
    // 获取折线图的数据
    this.getEcharts()
    // 采集范围
    this.getwebSite()
    // 关键词
    this.getKeyWords()
    // 舆情
    this.getArticlePage()
    // 重点关注
    this.getRemarkPage()

    logRead()
    // siteConfig(1, 10)

    this.headArr[0].memo1 = '开通日期：' + this.info.createTime
    this.headArr[0].memo2 = '结束日期：' + this.info.endDate

    // this.getEchartsLine('echarts', [1, 2, 3, 4, 5], [12, 56, 3, 44, 77])
  },
  methods: {
    goPath(path) {
      this.$router.push(path)
    },
    // 社区方案
    communityData() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }

      getSubredditMonitorTasks(obj).then(res => {
        this.communityList = res.items
      })
    },
    communityRetrieval() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }
      getSubredditsByQuery(obj).then(data => {
        this.communityArr = data.items
      })
    },
    // 账号方案
    accountScheme() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }
      getUserMonitorTasks(obj).then(res => {
        this.accountList = res.items
      })
    },
    getUserData() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }
      getUsersByQuery(obj).then(res => {
        this.userList = res.items
      })
    },
    getHead() {
      statistics().then(res => {
        // this.headArr[0].memo1 =
        //   '开通日期：' + this.$store.state.user.userInfo.create_time
        // this.headArr[0].memo2 =
        //   '结束日期：' + this.$store.state.user.userInfo.endTime
        this.headArr[1].memo1 = res.articleCount
        this.headArr[2].memo1 = res.configCount
        this.headArr[3].memo1 = res.planCount
        this.headArr[4].memo1 = res.remarkCount
        this.headArr[5].memo1 = res.searchLogCount
        // this.headArr[6].memo1 = res.redditCommentCount
      })
    },
    getEcharts() {
      collectionAmount().then(res => {
        this.getEchartsLine(
          'echarts',
          res.map(i => {
            return i.key_as_string
          }),
          res.map(i => {
            return i.doc_count
          })
        )
      })
    },
    getEchartsLine(dom, dataX, dataY) {
      const chart = echarts.init(document.getElementById(dom))
      const option = {
        grid: {
          x: 60,
          y: 40,
          x2: 40,
          y2: 20,
          borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          boundaryGap: false
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          // 提示框组件
          trigger: 'item', // 触发类型,'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。 'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          triggerOn: 'mousemove', // 提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
          showContent: true, // 是否显示提示框浮层
          alwaysShowContent: false, // 是否永远显示提示框内容
          showDelay: 0, // 浮层显示的延迟，单位为 ms
          hideDelay: 100, // 浮层隐藏的延迟，单位为 ms
          enterable: false, // 鼠标是否可进入提示框浮层中
          confine: false, // 是否将 tooltip 框限制在图表的区域内
          transitionDuration: 0.4, // 提示框浮层的移动动画过渡时间，单位是 s,设置为 0 的时候会紧跟着鼠标移动
          // position: ["50%", "50%"], //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置,[10, 10],回掉函数，inside鼠标所在图形的内部中心位置，top、left、bottom、right鼠标所在图形上侧，左侧，下侧，右侧，
          // formatter: "{b0}: {c0}<br />{b1}: {c1}", //提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}，分别表示系列名，数据名，数据值等
          backgroundColor: 'transparent', // 标题背景色
          borderColor: '#ccc', // 边框颜色
          borderWidth: 0, // 边框线宽
          padding: 5 // 图例内边距，单位px  5  [5, 10]  [5,10,5,10]
        },

        series: [
          {
            data: dataY,
            type: 'line',
            // smooth: true,
            lineStyle: {
              width: '4',
              color: '#5362EE'
            },
            // symbol: "none",
            color: '#5362EE',
            symbolSize: 10,
            // label: { show: true },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D5E2FB'
                },
                {
                  offset: 1,
                  color: '#D5E2FB'
                }
              ])
            }
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    },
    // 采集范围
    getwebSite() {
      webSite(1, 6).then(res => {
        this.communityList = res.items
      })
    },
    // 关键词
    getKeyWords() {
      planPage(1, 8).then(data => {
        this.accountList = data.items
      })
    },
    // 舆情
    getArticlePage() {
      articlePage(1, 10, {}).then(res => {
        this.communityArr = res.items
      })
    },
    // 重点关注
    getRemarkPage() {
      remarkPage(1, 10).then(res => {
        this.userList = res.items.map(item => {
          return item.article
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reddit-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: rgb(240, 242, 245);

  .rediit-head {
    width: 100%;
    height: 131px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .head-item {
      width: calc(100% / 6 - 1px);
      height: 131px;
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        width: 28px;
        height: auto;
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 20px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .reddit-content {
    height: 337px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    > div {
      // width: 33%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    > div:nth-child(1) {
      width: 40%;
    }
    > div:nth-child(2) {
      width: 43%;
    }
    > div:nth-child(3) {
      width: 16%;
    }
    #echarts {
      width: 100%;
      height: calc(100% - 40px);
    }
  }
  .reddit-foot {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    > div {
      background: white;
      border-radius: 5px;
      height: 100%;
      padding: 5px;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    .foot-left {
      width: 49.5%;
    }
    .foot-right {
      width: 50%;
    }
  }
  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: rgb(11, 106, 214);
      }
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
}
.index-range-logo {
  height: 29px;
  object-fit: cover;
  vertical-align: middle;
}
</style>
