import request from '@/plugins/axios'

export function statistics() {
  return request({
    url: '/statistics',
    method: 'get'
  })
}

export function collectionAmount() {
  return request({
    url: '/statistics/collectionAmount',
    method: 'get'
  })
}

export function siteConfig(page, limit) {
  return request({
    url: `/web/site/config/${page}/${limit}`,
    method: 'post'
  })
}

export function logRead() {
  return request({
    url: `/log/read`,
    method: 'get'
  })
}
